import React from 'react';
import PropTypes from 'prop-types';

const FormKlaviyoSignup = ({ formId, style = {} }) => {
  return formId ? (
    <div className={`klaviyo-form-${formId}`} style={style}></div>
  ) : null;
};

FormKlaviyoSignup.propTypes = {
  formId: PropTypes.string.isRequired,
  style: PropTypes.object,
};

export default FormKlaviyoSignup;
